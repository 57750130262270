<template lang="pug">
svg(xmlns:svg='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 258 136' x='0' y='0' width='258px' height='136px' version='1.1')
  clipPath#clipw
    path(d='M111.38 17c14.3,0 24,16.94 24,18 0,12.86 -18.37,40.11 -25.85,50.15 -6.55,8.77 -14.56,16.88 -22.38,24.62 -3.26,3.23 -12.64,11.89 -16.77,12.23 0.13,-6 14.27,-51.79 16.22,-58.78 3.97,-14.27 8.54,-29.45 11.53,-44.47 1.47,-7.36 3.23,-9.12 0.25,-14.75l-18 8c0,11.82 -6.02,43.38 -11.94,53.07 -1.43,2.34 -2.65,4.6 -4.11,6.88 -5.17,8.06 -37.99,49.14 -48.95,50.05 1.26,-15.11 33,-94.03 33,-115 0,-5.98 -6.98,-1.02 -8.99,0.01l-21.36 9.65c-7.51,3.62 -8.68,2.7 -9.65,6.34 4.63,-1.08 16.02,-7.61 22,-9l-15.61 49.39c-2.8,8.4 -4.91,15.75 -7.77,24.24 -8.55,25.42 -12.26,26.78 3.38,40.37 -5.06,1.35 -5.32,2.45 -6,5 10.38,-2.42 33.31,-25.88 41.75,-34.25 6.36,-6.3 19.58,-24.53 21.25,-25.75 -0.67,8 -13,39.07 -13,40 0,5.51 7.65,11.49 10,15 -2.53,1.7 -4.09,1.6 -5,5 9.9,-2.3 38.08,-29.41 44.42,-37.58 14.5,-18.67 36.58,-43.54 36.58,-71.42 0,-12.26 -5.94,-18.65 -16,-21 -5.21,3.49 -9.5,8.77 -13,14z')
  clipPath#clipe
    path(d='M173.43 112.76c-12.55,2.92 -33,32.93 -33,-17 5.03,-1.17 10.32,-3.88 14.57,-6.42 7.92,-4.74 19.79,-10.15 25.59,-17.42 4.98,-6.23 11.53,-13.71 5.04,-21.36 -7.08,-8.34 -22.61,-1.59 -29.18,2.22 -14.41,8.37 -29.1,26.66 -31.89,45.13 -3.14,20.76 11.47,37.88 25.55,30.56 3.37,-1.75 5.53,-2.95 8.38,-4.65 3.42,-2.05 13.9,-7.18 14.94,-11.06zm1 -63c-6,-6 -27.51,8.73 -33,43 17,-8 37.5,-35.5 33,-43z')
  clipPath#clipb
    path(d='M200.17 106.76c0.33,-4.04 29.44,-87.05 33.64,-98.4 1.34,-3.63 3.61,-5.98 0.64,-7.83 -3.97,-2.48 -27.39,11.66 -34.28,12.23l0 2c4.27,-0.99 13.31,-5.05 17,-7 -3.09,13.26 -23.57,72.86 -28.64,88.36 -5.61,17.2 -2.84,17.24 -9.72,20.28 -3.67,1.63 -2.38,1.09 -4.64,5.36 14.41,3.36 21.04,11.51 33.9,7.07 7.19,-2.49 16.33,-8.91 29.85,-22.32 6.94,-6.88 15.89,-18.39 18.64,-29.36 3.41,-13.64 -1.78,-28.69 -13.39,-31.39 -7,5.13 -41.06,59.58 -43,61 -0.82,2.81 -2.15,7.63 -2.1,10.46 1.04,-5.65 36.18,-57.49 39.1,-58.46 0,0 5,5 5,11 0,6 -14.53,46.65 -26,52 -5.37,2.51 -11.76,0.91 -15.33,-1.29 -1.41,-0.88 -2.51,-2.24 -2.67,-2.71 -0.6,-1.81 1.23,-8.22 2,-11z')
  path#whandwritting.handwritting-web.cw(
    clip-path='url(#clipw)' fill='none' stroke-width='22' stroke-miterlimit='10'
    d='M118.92 9.19c5.53,1.69 14.71,0.72 18.63,18.54 3.93,17.82 -35.24,73.82 -70.06,100.15 -15.23,-8.36 29.63,-120.91 24.26,-121.74 -2.46,-0.38 -24.14,47.24 -35.57,63.49 -22.34,31.77 -45.31,60.55 -46.22,57.75 -8.42,-25.93 37.79,-117.58 34.25,-120.87 -3.53,-3.28 -26.06,11.03 -33.99,15.14')
  path#ehandwritting.handwritting-web.ce(
    clip-path='url(#clipe)' fill='none' stroke-width='18' stroke-miterlimit='10'
    d='M171.48 114.21c-7.04,3.38 -20.64,13.99 -26.59,12.75 -21.5,-4.5 -17.74,-67.7 18.66,-81.1 10.84,-3.99 22.79,3.89 21.22,12.01 -3.27,16.85 -32.18,39.38 -41.99,42.26')
  path#bhandwritting.handwritting-web.cb(
    clip-path='url(#clipb)' fill='none' stroke-width='18' stroke-miterlimit='10'
    d='M198.92 111.27c4.6,-7.09 42.15,-64.89 44.5,-61.5 11.5,16.5 8.08,39.82 -24.62,73.26 -8.99,9.2 -31.12,-0.21 -32.12,-3.21 -1,-3 44.24,-113.16 40.74,-115.05 -3.5,-1.9 -24.96,9.09 -24.96,9.09')
</template>

<script>
export default {
  name: 'SvgWeb'
}
</script>

<style lang="scss" scoped>
.handwritting-web {
  stroke: #14cf93;
  stroke-dasharray: 596, 596;
  stroke-dashoffset: -596px;  /* Path length = 596 */
  animation-duration: 1s;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  fill: transparent;
  animation-name: handwritting;

  &.cw {
    animation-duration: 1.5s;
  }

  &.ce {
    stroke-dasharray: 219, 219;
    stroke-dashoffset: -219px;
    animation-delay: 5.5s;
  }

  &.cb {
    stroke-dasharray: 344, 344;
    stroke-dashoffset: -344px;
    animation-delay: 6.5s;
  }
}

@-moz-keyframes handwritting { 100% { stroke-dashoffset: 0; } }
@-webkit-keyframes handwritting { 100% { stroke-dashoffset: 0; } }
@-o-keyframes handwritting { 100% { stroke-dashoffset: 0; } }
@keyframes handwritting { 100% { stroke-dashoffset: 0; } }

</style>
